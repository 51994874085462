<template>
    <div>
        <van-skeleton title avatar :row="3" :loading="loading">
            <TopHeader></TopHeader>
            <TopSearch></TopSearch>
            <TopBanner :banners="banners"></TopBanner>
            <AppList :colNum="4" :apps="apps" :fetchList="refreshAdvertList"></AppList>
        </van-skeleton>
        <br/>
        <p>&nbsp;</p>
    </div>
    </template>
    
    <script>
    import TopHeader from '@/components/TopHeader.vue'
    import TopSearch from '@/components/TopSearch.vue'
    import TopBanner   from '@/components/TopBanner.vue';
    import AppList from '@/components/AppList.vue';
    import HttpHelper from '@/utils/HttpHelper';
    import GlobalConfig from '@/utils/GlobalConfig';

    export default {
        name:"HomeNavigator",
        data() {
            return {
                loading:true,
                apps:[],
                banners:[],
            }
        },
    
        components:{
            TopSearch,
            TopHeader,
            TopBanner,
            AppList
        },
    
        methods:{
            finishLoad() {
                this.loading = false;
            },
    
            refreshBanner(){
                HttpHelper.queryBannerList("", (code, data) =>{
                    if (code != 200) {
                        console.log("query banner fail ", code)
                        return;
                    }

                    console.log("get banner adverts:" , data);
                    this.banners = data;
                });
            },


            refreshAdvertList(cb) {
                HttpHelper.queryAdvertList("", (code, data) =>{
                    if (code != 200) {
                        console.log("query icon fail ", code)
                        return;
                    }

                    console.log("get icon adverts:" , data);
                    if (data && data.length > 0) {
                        for (var i = 0; i < data.length; i++) {
                            data[i].kind = 1
                        }
                    }

                    this.apps = data;
                    this.finishLoad();

                    GlobalConfig.getInstance().iconAdverts = data;
                    console.log("icon adverts = ", GlobalConfig.getInstance().iconAdverts)
                    if (cb)cb();
                });  
            }
        },
    
        mounted() {
            this.refreshAdvertList();
            this.refreshBanner();
        }
    }
    </script>