<template>
  
    <div>
      <keep-alive>
        <component v-bind:is="currentTabComponent" class="tab"></component>
      </keep-alive>

      <van-tabbar  v-model="activeIndex" active-color="#ee0a24" inactive-color="#000" @change="onChange">
        <van-tabbar-item>
          <span>搜索</span>
          <template #icon="props">
            <img :src="props.active ? nidongde.active : nidongde.inactive" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item >
          <span>导航</span>
          <template #icon="props">
            <img :src="props.active ? daohang.active : daohang.inactive" />
          </template>
      </van-tabbar-item>
  </van-tabbar>
    </div>
  </template>
  
  <script>
  import NddNormal from '@/assets/images/icon_tab_ni_dong_de_normal.webp'
  import NddSelected from '@/assets/images/icon_tab_ni_dong_de_selected.webp'
  import DhSelected from '@/assets/images/icon_tab_dao_hang_selected.webp'
  import DhNormal from '@/assets/images/icon_tab_dao_hang_normal.webp'
  import AppsView from './AppsView.vue'
  import HomeNavigator from './HomeNavigator.vue'
  
  export default {
    name: 'HomePage',
    data (){
      return {
        activeIndex:0,
        tabComponents:["AppsView", "HomeNavigator"],
        nidongde: {
          active: NddSelected,
          inactive: NddNormal,
        },
        daohang: {
          active: DhSelected,
          inactive: DhNormal,
        }
      };
    },

    computed:{
          currentTabComponent(){
            return this.tabComponents[this.activeIndex];
          },
    },
    components:{
      AppsView,
      HomeNavigator
    },

    methods:{
      onChange(idx){
        console.log(idx);
      }
    }
  }
  </script>
  