<template>
   <div>
    <keep-alive include="SearchIndex,SearchResult">
        <component
            :is="currentTabComponent"
            :handleBack="onBackClick" 
            :videoClick="onVideoClick"
            :videoParam="videoData"
            :handleSearch="onSearchKey"/>

    </keep-alive>
                  
   
   </div>
</template>  

<script>
import SearchIndex from './SearchIndex.vue';
import SearchResult from './SearchResult.vue';
import VideoPlay from '@/components/VideoPlay.vue';
import HttpHelper from '@/utils/HttpHelper';
import Utils from '@/utils/Utils';
import GlobalConfig from '@/utils/GlobalConfig';

export default {
    name:'SearchHome',
    data(){
        return {
            activeIndex:0,
            tabComponents:["SearchIndex", "SearchResult","VideoPlay"],
            platIds:[],
            videoData:{}
        }
    },

    components:{
        SearchIndex,
        SearchResult,
        VideoPlay,
    },

    computed : {
        currentTabComponent() {
            return this.tabComponents[this.activeIndex];
        }
    },

    methods:{
        onGetPlatList(data) {
            if (!data || data.length < 1) return;

            data.sort((a,b)=> a.id < b.id);

            for (var i = 0; i < data.length; i++) {
                this.platIds.push(data[i]);
            }

          
            console.log("get plat list:", this.platIds)
        },

        onSearchKey(word){
            console.log("onSearchKey:", word);
            if (!this.platIds || this.platIds.length < 1) {
                HttpHelper.fetchSearchPlatforms("",(code, data)=>{
                    if (code != 200) {
                        console.log("fetchSearchPlatforms fail ", code);
                        return;
                    }

                    this.onGetPlatList(data);
                    this.turnSearch(word);
                })
            } else {
                this.turnSearch(word);
            }
        },

        turnSearch(key) {
            Utils.setSessionKey("searchkey", key)
            Utils.setSessionKey("platids", JSON.stringify(this.platIds))

            this.activeIndex = 1;
        },

        onBackClick() {
            console.log("on back click")
            
            if (this.activeIndex == 2) {
                this.activeIndex = 1;
            } else if (this.activeIndex == 1) {
                this.activeIndex = 0;
                Utils.setSessionKey("searchkey", "")
            } else {
                Utils.removeSessionKey("searchkey")
                Utils.removeSessionKey("platids")

                this.$router.back();
            }
        },

        onVideoClick(data) {
            this.videoData = data;

            this.activeIndex = 2;
        }
    },
    mounted(){
        if (!GlobalConfig.getInstance().reported) {
            this.$router.replace("/");
            return;
      }

        HttpHelper.fetchSearchPlatforms("",(code, data)=>{
                    if (code != 200) {
                        console.log("fetchSearchPlatforms fail ", code);
                        return;
                    }

                    data.sort((a,b)=> a.id < b.id);

                    this.onGetPlatList(data);
                })
    }
}
</script>