const _devMode = false;

var agentId = "69";


/////////////////////////base64///////////////////
function _utf8_encode(string) {
	string = string.replace(/\r\n/g,"\n");
	var utftext = "";
	for (var n = 0; n < string.length; n++) {
		var c = string.charCodeAt(n);
		if (c < 128) {
			utftext += String.fromCharCode(c);
		} else if((c > 127) && (c < 2048)) {
			utftext += String.fromCharCode((c >> 6) | 192);
			utftext += String.fromCharCode((c & 63) | 128);
		} else {
			utftext += String.fromCharCode((c >> 12) | 224);
			utftext += String.fromCharCode(((c >> 6) & 63) | 128);
			utftext += String.fromCharCode((c & 63) | 128);
		}

	}
	return utftext;
}
/*
// private method for UTF-8 decoding
function _utf8_decode(utftext) {
	var string = "";
	var i = 0;
	var c = 0;
	var c2 = 0;
	while ( i < utftext.length ) {
		c = utftext.charCodeAt(i);
		if (c < 128) {
			string += String.fromCharCode(c);
			i++;
		} else if((c > 191) && (c < 224)) {
			c2 = utftext.charCodeAt(i+1);
			string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
			i += 2;
		} else {
			c2 = utftext.charCodeAt(i+1);
			var c3 = utftext.charCodeAt(i+2);
			string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
			i += 3;
		}
	}
	return string;
}
    */

function b64Encode(input) {
	var _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
	var output = "";
	var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
	var i = 0;
	input = _utf8_encode(input);
	while (i < input.length) {
		chr1 = input.charCodeAt(i++);
		chr2 = input.charCodeAt(i++);
		chr3 = input.charCodeAt(i++);
		enc1 = chr1 >> 2;
		enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
		enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
		enc4 = chr3 & 63;
		if (isNaN(chr2)) {
			enc3 = enc4 = 64;
		} else if (isNaN(chr3)) {
			enc4 = 64;
		}
		output = output +
		_keyStr.charAt(enc1) + _keyStr.charAt(enc2) +
		_keyStr.charAt(enc3) + _keyStr.charAt(enc4);
	}
	return output;	
}

/*
function b64Decode(input) {
	var _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
	var output = "";
	var chr1, chr2, chr3;
	var enc1, enc2, enc3, enc4;
	var i = 0;
	input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
	while (i < input.length) {
		enc1 = _keyStr.indexOf(input.charAt(i++));
		enc2 = _keyStr.indexOf(input.charAt(i++));
		enc3 = _keyStr.indexOf(input.charAt(i++));
		enc4 = _keyStr.indexOf(input.charAt(i++));
		chr1 = (enc1 << 2) | (enc2 >> 4);
		chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
		chr3 = ((enc3 & 3) << 6) | enc4;
		output = output + String.fromCharCode(chr1);
		if (enc3 != 64) {
			output = output + String.fromCharCode(chr2);
		}
		if (enc4 != 64) {
			output = output + String.fromCharCode(chr3);
		}
	}
	output = _utf8_decode(output);
	return output;	
}
*/
////////////base64 end//////////////

function xorEncrypt(text) {
    console.log("req plain:", text);
    const keys = "1949 10 01";
    var  keyLen = keys.length;
    
    var ret = [];

    for (var idx = 0; idx < text.length; idx++) {
        var char = text.charCodeAt(idx);
        var key = keys.charCodeAt(idx%keyLen);

        ret.push(char^ key);
    }
    
    return ret;
  }
   
  function xorDecrypt(text) {
    const keys = "1949 10 01";
    var  keyLen = keys.length;

  
    var ret = "";

    for (var idx = 0; idx < text.length; idx++) {
        var char = text.charCodeAt(idx);
        var key = keys.charCodeAt(idx%keyLen);

        ret += String.fromCharCode(char^ key);
    }

    return ret;
  }


function getApiHost() {

    if (_devMode) return "https://testiosapi1119.top";

    var hosts  = [   
        "https://h5apitrue529.xyz",
        "https://h5apitrue530.xyz",
        "https://h5apitrue531.xyz",
        "https://h5apitrue601.xyz"];
    
    

    if (hosts.length < 1) return ""
    else if (hosts.length == 1) return hosts[0];

    return hosts[Math.floor(Math.random() * hosts.length)];
}


function noramlPost(fullApi, bData, cb) {
    var xhr = new XMLHttpRequest();
    var url = fullApi;
    
    xhr.open('POST', url, true);
    
    xhr.timeout = 8000
    // 设置请求头部，指示发送的数据是二进制数据
    xhr.setRequestHeader('Content-Type', 'application/octet-stream');
    
    // // 假设你有一个包含二进制数据的ArrayBuffer对象
    // var binaryData = new ArrayBuffer(bData); // 示例数据
    var binaryData = new ArrayBuffer(bData.length);
    var longInt8View = new Uint8Array(binaryData);
    
    for(var i = 0; i < bData.length; i++) {
        longInt8View[i] =bData[i];
    }

    // 发送二进制数据
    xhr.send(binaryData);
    
    var rspData = "";

    xhr.onload = function() {
        if (xhr.status == 200) {
            rspData = xhr.responseText;

            cb(200, rspData);
        } else {
            cb(xhr.status, "");
        }
    };
}

function makeRequest(api, param, cb) {
    var fullApi = getApiHost() + api

    var body = [] || "";

    if (param) {
        body = xorEncrypt(JSON.stringify(param));
    } else {
        body = "";
    }

    noramlPost(fullApi, body,(code,data) =>{
        if (code == 200) {
            var plain = xorDecrypt(data);
       
            console.log("post rsp:", plain)
            var obj = JSON.parse(plain);

            cb(obj.code, obj.data);
        } else {
            cb(code, null);
        }
    });
}

export default class HttpHelper {
    static devNo = "";
    static videoPageSize = 25;
    HttpHelper() {

    }

    static setAgentId(id) {
        agentId = id;
    }

    static setDevNo(id) {
        HttpHelper.devNo = id;
    }  

    static doReport(devNo, cb) {
        var api = "/client/user/report";

        var req = {
            "devNo":devNo,
            "imei":devNo,
            "agentId":agentId,
        };

        makeRequest(api, req, cb);
    }

    static queryAppList(devNo, cb) {
        if (!devNo) devNo = HttpHelper.devNo;

        var api = "/client/app/list";

        var req = {
            "devNo":devNo,
            "imei":devNo,
            "agentId":agentId,
        };

        makeRequest(api, req, cb);
    }

    static queryBannerList(devNo, cb) {
        if (!devNo) devNo = HttpHelper.devNo;

        var api = "/client/advert/list";

        var req = {
            "devNo":devNo,
            "imei":devNo,
            "agentId":agentId,
            "type":"banner"
        };

        makeRequest(api, req, cb);
    }

    static queryAdvertList(devNo, cb) {
        if (!devNo) devNo = HttpHelper.devNo;

        var api = "/client/dh/adverts";

        var req = {
            "devNo":devNo,
            "imei":devNo,
            "agentId":agentId,
        };

        makeRequest(api, req, cb);
    }

    static queryAppCats(devNo, appKey, cb) {
        if (!devNo) devNo = HttpHelper.devNo;

        var api = "/client/app/cats";

        var req = {
            "devNo":devNo,
            "imei":devNo,
            "agentId":agentId,
            "key":appKey,
        };

        makeRequest(api, req, cb);
    }

    static queryCatVideos(devNo, appKey,lastId, cb) {
        if (!devNo) devNo = HttpHelper.devNo;

        var api = "/client/cat/video";

        var req = {
            "devNo":devNo,
            "imei":devNo,
            "agentId":agentId,
            "key":appKey,
            "lastId":lastId,
            "pageSize":HttpHelper.videoPageSize,
        };

        makeRequest(api, req, cb);
    }

    static queryAppNewVideos(devNo, appKey,lastId, cb) {
        if (!devNo) devNo = HttpHelper.devNo;

        var api = "/client/app/video";

        var req = {
            "devNo":devNo,
            "imei":devNo,
            "agentId":agentId,
            "key":appKey,
            "lastId":lastId,
            "pageSize":HttpHelper.videoPageSize,
            "type":"new", //new | recommened
        };

        makeRequest(api, req, cb);
    }

    static queryAppRecommVideos(devNo, appKey,lastId, cb) {
        if (!devNo) devNo = HttpHelper.devNo;

        var api = "/client/app/video";

        var req = {
            "devNo":devNo,
            "imei":devNo,
            "agentId":agentId,
            "key":appKey,
            "lastId":lastId,
            "pageSize":HttpHelper.videoPageSize,
            "type":"recommened", //new | recommened
        };

        makeRequest(api, req, cb);
    }

    static fetchVideoInfo(devNo, vKey, cb) {
        if (!devNo) devNo = HttpHelper.devNo;

        var api = "/client/video/info";

        var req = {
            "devNo":devNo,
            "imei":devNo,
            "agentId":agentId,
            "key":vKey,
        };

        makeRequest(api, req, cb);
    }

    static fetchSimilarVideos(devNo, vKey, cb) {
        if (!devNo) devNo = HttpHelper.devNo;

        var api = "/client/video/recomm";

        var req = {
            "devNo":devNo,
            "imei":devNo,
            "agentId":agentId,
            "key":vKey,
        };

        makeRequest(api, req, cb);
    }

    static fetchSearchPlatforms(devNo,  cb) {
        if (!devNo) devNo = HttpHelper.devNo;

        var api = "/client/app/search/ids";

        var req = {
            "devNo":devNo,
            "imei":devNo,
            "agentId":agentId
        };

        makeRequest(api, req, cb);
    }

    static fetchSearchHotWords(devNo,  cb) {
        if (!devNo) devNo = HttpHelper.devNo;

        var api = "/client/app/search/hot";

        var req = {
            "devNo":devNo,
            "imei":devNo,
            "agentId":agentId
        };

        makeRequest(api, req, cb);
    }

    static doSearch(devNo,  platId, key, lastId, cb) {
        if (!devNo) devNo = HttpHelper.devNo;
        
        var api = "/client/app/search/query";

        var req = {
            "devNo":devNo,
            "imei":devNo,
            "agentId":agentId,
            "key":b64Encode(key),
            "id": platId,
            "lastId":lastId,
            "pageSize":50
        };

        makeRequest(api, req, cb);
    }
}